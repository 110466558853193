import { A } from "@solidjs/router";
import { For, Show } from "solid-js";

interface Breadcrumb {
  label: string;
  href: string;
  active?: boolean;
}

interface BreadcrumbsNavProps {
  breadcrumbs: Breadcrumb[];
}

export function BreadcrumbsNav({ breadcrumbs }: BreadcrumbsNavProps) {
  return (
    <nav aria-label="Breadcrumb" class="block">
      <ol class={`flex items-center`}>
        <Show when={breadcrumbs.length > 0} fallback={<></>}>
          <For each={breadcrumbs}>
            {(breadcrumb, index) => (
              <li
                aria-current={breadcrumb.active ? "page" : undefined}
                class={`flex items-center text-medium text-textDark`}
              >
                <A
                  href={breadcrumb.href}
                  class={`${breadcrumb.active ? "" : "cursor-pointer"}`}
                >
                  {breadcrumb.label}
                </A>
                {index() < breadcrumbs.length - 1 ? (
                  <span class="mx-3 inline-block text-[19px] font-light leading-relaxed  text-textNormal">
                    /
                  </span>
                ) : null}
              </li>
            )}
          </For>
        </Show>
      </ol>
    </nav>
  );
}
